@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Noto Sans KR", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}

html,
body,
#root,
.App {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

input[type="password"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

*::placeholder {
  color: #d8d8d8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 30pt;
}

h2 {
  font-size: 24pt;
}

h3 {
  font-size: 20pt;
}

h4 {
  font-size: 18pt;
}

h5 {
  font-size: 16pt;
}

h6 {
  font-size: 14pt;
}

ul.after-arrow-right li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

ul.after-arrow-right li + li::after {
  position: absolute;
  content: ">";
  left: -15px;
  top: -1px;
}

span.required::before {
  content: "*";
  color: #4c1d95;
  margin-right: 4px;
}

.box-shadow {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.08);
}

.keep-all {
  word-break: keep-all;
}

.break-all {
  word-break: break-all;
}

.react-datepicker-popper {
  z-index: 40;
}

.react-datepicker__day--selected {
  background-color: #f5f3ff;
  color: #4c1d95;
  font-weight: bold;
}

.react-datepicker__day--selected:hover {
  background-color: #f5f3ff;
}

.react-datepicker__day--today {
  background-color: transparent;
  background-color: #4c1d95;
  color: white;
}
